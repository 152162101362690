<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">vendor</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage vendors</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
  
    <v-card
      class="create-card cardBg py-15 px-12 rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      flat
      outlined
    >
      <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
        <v-col cols="12" md="4" lg="2">
          <TextDescription :description="description[0]" />
        </v-col>
      
        <v-col cols="12" md="8" lg="10">
          <v-form ref="form" v-model="validateForm">
            <v-row no-gutters>
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Vendor name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="vendorName"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataVendor.name"
                  :rules="nameRules"
                  :loading="loadingDetailVendor"
                  class="field-container"
                >
                  <template #message="{ key, message }">
                    <portal to="vendorName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>

              <v-col
                class="d-flex align-center justify-start justify-sm-end pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-large sectionTitle--text text-sm-right">Permissions</span>
              </v-col>
              <v-col class="pb-0 pb-sm-12" cols="12" sm="7" md="9"></v-col>
            
              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="3"
              >
                Number creation
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <SwitchLg
                  v-model="formDataVendor['create_numbers']"
                  bgColor="switcherViolet"
                />
              </v-col>
            
              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="3"
              >
                See record
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <SwitchLg
                  v-model="formDataVendor['see_record']"
                  bgColor="switcherViolet"
                />
              </v-col>

              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="3"
              >
                Block numbers
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <SwitchLg
                  v-model="formDataVendor['block_numbers']"
                  bgColor="switcherViolet"
                />
              </v-col>

              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="3"
              >
                Download reports
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <SwitchLg
                  v-model="formDataVendor['download_reports']"
                  bgColor="switcherViolet"
                />
              </v-col>
            
              <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
                <CancelButton @click="closeAction" class="mr-8 mr-sm-10">Cancel</CancelButton>
              
                <ActionButton type="submit" @click.prevent="submitForm" :loading="loadingAction">
                  {{ vendorSlug ? 'Update' : 'Create' }}
                </ActionButton>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import SwitchLg from '@/components/controls/SwitchLg'

import { getDetailVendor, createVendor, updateVendor } from '@/api/vendor-routes'

export default {
  name: 'DetailVendor',
  components: { ActionButton, CancelButton, TextDescription, FormField, SwitchLg },
  data: () => ({
    validateForm: false,
    formDataVendor: {
      name: '',
      'create_numbers': false,
      'see_record': false,
      'block_numbers': false,
      'download_reports': false,
      'status_id': 1,
    },
    nameRules: [],
    description: [
      {
        title: 'Setup your vendor',
        text: []
      },
    ],
    vendorSlug: null,
    loadingDetailVendor: false,
    loadingAction: false,
    createdVendorSuccess: false,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.vendorSlug = this.$route.params.slug
      this.loadDetailVendor()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageVendors' })
    },
    async loadDetailVendor() {
      this.loadingDetailVendor = true
      
      const { success, payload, message } = await getDetailVendor(this.vendorSlug)
      
      if (success) {
        this.formDataVendor = {
          name: payload.name,
          'status_id': payload['status_id'],
          'create_numbers': !!payload['create_numbers'],
          'see_record': !!payload['see_record'],
          'block_numbers': !!payload['block_numbers'],
          'download_reports': !!payload['download_reports'],
        }
        this.createdVendorSuccess = true
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingDetailVendor = false
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        if (this.createdVendorSuccess) {
          this.updateVendor()
        } else {
          this.createVendor()
        }
      }
    },
    validationForm() {
      this.nameRules = [
        v => !!v || 'This field is required'
      ]
    },
    async createVendor() {
      this.loadingAction = true
      
      const { success, message } = await createVendor(this.formDataVendor)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    async updateVendor() {
      this.loadingAction = true

      const { success, message } = await updateVendor({
        vendorId: this.vendorSlug,
        formData: this.formDataVendor
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
  },
}
</script>
